<template>
  <div class="app-s-container">
    <div class="page-outter">
      <div align="center">
        <img
          class="app-s-title"
          v-lazy="require('../../../assets/images/title1.png')"
        />
        <div class="app-s-d1">
          <ul>
            <li
              v-for="(item,index) in tds"
              :key="index"
            >
              <div
                class="p-item"
                align="center"
              >
                <img
                  class="m1"
                  v-lazy="item.icon"
                />
                <span
                  class="s1"
                  v-html="item.name"
                ></span>
              </div>
            </li>
          </ul>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ApplicationScenario-smw",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/app-icon15.png"),
          name: "支持多种出入库流程</br>和库内管理策略",
        },
        {
          icon: require("../../../assets/images/app-icon14.png"),
          name: "仓储信息全方位可视化</br>提高决策准确性",
        },
        {
          icon: require("../../../assets/images/app-icon13.png"),
          name: "人性化操作界面，简单易懂</br>培训上岗成本低",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.app-s-container {
  width: 100%;
  background: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.app-s-title {
  display: block;
  width: 7rem;
  margin-top: 2.4rem;
}
.app-s-d1 {
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-left: -4rem;
    li {
      float: left;
      margin-left: 4rem;
      margin-top: 1.5rem;
      .p-item {
        width: 200px;
        height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #f3f3f3;
        .m1 {
          width: 54px;
        }
        .s1 {
          display: block;
          font-size: 14px;
          color: #6aa5ff;
          margin-top: 0.4rem;
        }
      }
    }
  }
}
</style>